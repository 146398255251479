<template>
    <div class="year-slider">
        <b-container>           
            <b-row ref="yearSlideText">
                <b-col cols="12" md="2">
                    <div class="year-slider__nav">
                        <div class="prev-btn" @click="selectNewSliderItem(currentItemIndex - 1)" :class="{'inactive': currentItemIndex ===0}"></div>
                        <div class="next-btn" @click="selectNewSliderItem(currentItemIndex + 1)" :class="{'inactive': currentItemIndex === slides.length - 1}"></div>
                    </div>
                </b-col>
                <b-col cols="12" md="10" class="year-slider__data">
                    <div class="year-slider__text-block">                        
                        <div class="year double-year" v-if="sepYear(currentSlide.name).length > 1">
                            <span>{{ sepYear(currentSlide.name)[0] }} -</span><br/>{{sepYear(currentSlide.name)[1]}}
                        </div>
                        <div v-else class="year">{{ currentSlide.name }}</div>
                        <div class="sep"></div>
                        <div class="desc">{{ currentSlide.description }}</div>
                    </div> 
                </b-col>
            </b-row>
            <div class="sep-row" ref="sepRow"></div>
            <b-row class="year-slider__content">                                  
                <b-col cols="12" md="2" class="year-slider__slider">
                    <div id="setHeightElement" class="year-slider__carousel-wrap">
                        <div class="year-slider__carousel" id="yearCarousel" style="transform: translateY(0)">                        
                            <div class="year-slider__year" 
                                @click="selectItem(slide)"
                                v-for="(slide, index) in years" :key="index" 
                                :class="[{'hasSubYear': slide.length > 1}, {'active-year': currentYear(slide[0])}]"
                            >
                                <div class="year-item" >
                                    {{ slide[0] }}
                                </div>        
                                <div class="year-item-sub" v-if="slide.length > 1">
                                    <span>-</span>
                                    <span>{{slide[1]}}</span>
                                </div>                   
                            </div>
                        </div>    
                    </div>
                </b-col>
                <b-col cols="12" md="10" >                                                                    
                    <div class="img-wrap" ref="refHeightElementWrap">
                        <img 
                            :src="src_url + currentSlide.image.path + currentSlide.image.file_name" 
                            class="img-fluid main-yearslider-img" 
                            ref="refHeightElement" 
                            @load="imgLoad" 

                        />    
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>

export default {
    props:['slides'],    
    data(){
        return{
            currentSlide: this.slides[0],
            maxSlideItem: 5,
            imgLoaded : false,
            sliderHeight: 0,
            yearItemHeight: 0,
            yearItemMargin: 0,
            currentItemIndex: 0,
            years:[],            
        }
    },          
    created(){
        this.getYears()
    },   
    mounted(){
        window.addEventListener('resize', this.yearSliderHeight);
    },
    destroyed(){
        window.removeEventListener('resize', this.yearSliderHeight);
    },
    // computed:{
    //     doubleYear(){
    //         return this.currentSlide.name.indexOf('-') !== -1
    //     }
    // } ,
    methods:{    
        sepYear(year){                   
            let yearArray = year.replace(/\s/g, '').split('-');
            return yearArray
        },      
        getYears(){
            //get the two years format
            let _this = this

            this.slides.forEach(item => {
                let year = item.name.replace(/\s/g, '').split('-');
                _this.years.push(year)
            })
        },
        currentYear(item){
            let _this = this            
            let currentSlideYearFirstYear = _this.currentSlide.name.replace(/\s/g, '').split('-')[0]
            return currentSlideYearFirstYear == item
        },        
        imgLoad(){
            if (!this.imgLoaded){                   
                this.imgLoaded = true            
                this.yearSliderHeight()                
            }
            
        },   
        yearSliderHeight(){
            let refHeight = this.$refs.refHeightElement  
            let refHeightWrap = this.$refs.refHeightElementWrap            
            let refHeightWrapMax = 0
            let topBlockHeight = this.$refs.yearSlideText.clientHeight + this.$refs.sepRow.clientHeight
            let itemHeight = 0      
            let setElement = document.getElementById('setHeightElement')                     
                        
            //set the reference element height reference to the viewport height
            refHeightWrapMax = window.innerHeight - topBlockHeight - 90
            if (refHeightWrap.clientHeight > refHeightWrapMax){
                refHeightWrap.style.height = refHeightWrapMax + 'px'
            }

            // console.log('yearslider', window.innerHeight, topBlockHeight)
                        
            //set the slider container height                        
            if (refHeight && this.imgLoaded){                
                itemHeight = refHeight.offsetHeight
            }             
            
            this.sliderHeight = itemHeight            

            setElement.style.height = itemHeight + 'px'
            this.setSliderItems()
            
        },    
        setSliderItems(){
            let _this = this
            let yearItemHeight = document.getElementsByClassName('year-item')[0].offsetHeight            
            let needMargin = ( this.sliderHeight - ( _this.maxSlideItem * yearItemHeight) ) / (_this.maxSlideItem  - 1 )
            let yearsItems = document.getElementsByClassName('year-item')

            yearsItems.forEach(element => {
                element.style.marginBottom = needMargin + 'px'    
            });

            this.yearItemHeight = yearItemHeight
            this.yearItemMargin = needMargin
        },
        selectItem(sitem){                        
            let newsItemIndex = this.slides.findIndex( element => element.name.replace(/\s/g, '').split('-')[0] == sitem[0])            
            this.selectNewSliderItem(newsItemIndex)
        },     
        selectNewSliderItem(newIndex){
            let _this = this            
            let carousel = document.getElementById('yearCarousel')
            let step = _this.yearItemHeight + _this.yearItemMargin                                          
            let maxDiff = carousel.clientHeight - this.sliderHeight
            let carouselTranslateY = _this.getTranslateY(carousel)

            // console.log(maxDiff, newIndex, carouselTranslateY)                                    

            if ( 
                ( newIndex < _this.currentItemIndex && !(newIndex < 0 )) 
                || newIndex > _this.currentItemIndex && newIndex != _this.slides.length)
            {                
                //go to next slide
                let img = _this.$refs.refHeightElement
                img.classList.remove('show')
                img.classList.add('hide')                
                
                //next slide animation
                // setTimeout(() => {
                    _this.currentItemIndex = newIndex   
                    _this.currentSlide = _this.slides[_this.currentItemIndex]  
                    img.classList.remove('hide')
                    img.classList.add('show')                        
                // }, 100);
                
                               
            }  

            let translateYStep = -1 * (_this.currentItemIndex * step)

            // console.log('lépés', carouselTranslateY, -1*maxDiff, translateYStep )
            

            if ( 
                ( carouselTranslateY >= -1 * maxDiff && translateYStep < carouselTranslateY) ||
                  carouselTranslateY >= -1 * maxDiff && translateYStep > carouselTranslateY 
                )                
            {                        
                if (translateYStep < -1*maxDiff ){
                    carousel.style.transform = "translateY(" +  -1 * (maxDiff) + "px)";
                } else {
                    carousel.style.transform = "translateY(" + translateYStep + "px)";
                }
                
                // console.log('huz', translateYStep)                    
            }

            
                                   
        },
        getTranslateY(element){            
            var cssStyle = window.getComputedStyle(element)
            var cssTransform = cssStyle.getPropertyValue('transform')            

            // console.log(cssTransform)

            if (cssTransform != null || cssTransform != undefined || cssTransform != ''){
                var transformValues = cssTransform.match(/matrix.*\((.+)\)/)[1].split(', ')
                var translateYValue = Math.round(transformValues[5])
                return translateYValue                
            } else {
                return null
            }
                        
        }
    }

}
</script>