<template>
    <div class="history page">
        <!-- HISTORY LEAD TEXT         -->
        <HistoryLead :imageSlider="imageSlider" :text="historyLead.description"  v-if="historyLead"/>
        <!-- YEARSLIDER DESCTOP -->
        <section class="history__year-slider" v-if="historySlider.length > 0 && isDesctop" v-inview="'fade-up'" >
            <YearSlider :slides="historySlider" />
        </section>
        <!-- YEARSLIDER MOBILE -->
        <section class="history__year-slider" v-if="historySlider.length > 0 && !isDesctop" v-inview="'fade-up'" >
            <YearSliderMobile :slides="historySlider" />
        </section>        
        <!-- PRODUCT FAMILIES -->
        <section class="other-pdf-products" v-inview="'fade-up'" >
            <h3>termékcsaládjaink</h3>
            <ProdFamilySlider :needApiCall="true" :prodFamiliesAll="[]"/>
        </section>
        <PageLoading /> 
    </div>
</template>

<script>
import apiH from '@/mixins/apiHandlerMI.js'
import viewMI from '@/mixins/viewMI.js'
import ProdFamilySlider from '@/components/pages/productFamily/ProdFamilySlider.vue'
import YearSlider from '@/components/pages/history/YearSlider2.vue'
import YearSliderMobile from '@/components/pages/history/YearSliderMobile.vue'
import HistoryLead from '@/components/pages/history/HistoryLead.vue'
import PageLoading from '@/components/layout/PageLoading.vue'

export default {
    mixins: [apiH,viewMI],
    components:{        
        ProdFamilySlider,
        YearSlider,
        YearSliderMobile,
        HistoryLead,
        PageLoading
    },
    data(){
        return{
            identifier: 'sopronirol_lead',
            slider1Name: 'sopronirol-lead', 
            slider2Name: 'tortenetunk', 
            imageSlider:[],
            historySlider:[],            
            // loadCountNeed: 1,
            // currentPageLoadNum: 0        
        }
    },
    computed:{
        historyLead(){
            let _this = this
            let moduleText = null

            if (_this.currentPageModulesTexts.length > 0) {            
                moduleText = _this.currentPageModulesTexts.find(item => item.identifier == _this.identifier)
            } else {
                moduleText = null
            }

            return moduleText
        },          
    },
    methods:{
        loadSlider(){
            var _this = this;                       

            _this.get('slider',
                {
                    params: {                          
                        relations:1,                                       
                        where: [
                            ['where','status', '1'],
                            ['where_in','name', [_this.slider1Name, _this.slider2Name]],
                        ],
                        relations_params: {
                            slider_images: {
                                order_by:[['sort_order','asc']]
                            }
                        }
                    }
                },
                function(resp){                        
                    let sliders = resp.data.slider                    
                    
                    _this.historySlider = sliders.find(item => item.name === _this.slider2Name).slider_image                    
                    _this.imageSlider = sliders.find(item => item.name === _this.slider1Name).slider_image
                    
                     //increase the page load num 
                    // _this.currentPageLoadNum += 1
                    
                }, function(err){
                    console.log(err);                    
                    _this.imageSlider = []
                    _this.historySlider = []
                }
            )
        },        
    },
    created(){
        let _this = this
        this.getModuls( [_this.identifier])        
        this.loadSlider()         
        this.onDesctop() //decide is it a desctop or mobile device
    },
    mounted()  {
        this.setMetaTags({
            title: 'Soproni - Történetünk', 
            desc: null, 
            keywords: null, 
            ogType: 'website', 
            ogTitle: 'Soproni - Történetünk',                         
            ogImage: null, 
            ogImageWidth: null, 
            ogImageHeight: null, 
            ogDesc:null
        })
    }
}
</script>