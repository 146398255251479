<template>
    <div class="year-slider year-slider--mobile">
        <div class="year-slider__item" 
            v-for="slide in slides" :key="slide.id"             
            :class="{'hasSubYear': sepYear(slide.name).length > 1}"
        >            
            <div class="year-item">{{ sepYear(slide.name)[0] }}</div>
            <div class="year-item-sub" v-if="sepYear(slide.name).length > 1">
                <span>-</span>
                <span>{{sepYear(slide.name)[1]}}</span>
            </div>  
            <img 
                :src="src_url + slide.image.path + slide.image.file_name" 
                class="img-fluid"                                 
            />             
            <div class="desc">{{slide.description}}</div>
        </div>
    </div>
</template>

<script>
export default {
    props:['slides'],  
    mounted() {        
        let _this = this
        let yearItem = document.getElementsByClassName('year-slider__item')

        window.addEventListener('scroll', function(){
            // console.log(_this.isElementInViewport(yearItem[0]))          
            yearItem.forEach(element => {                      
                if (_this.isElementInViewport(element)) {
                    element.classList.add('active-year')
                } else {
                    element.classList.remove('active-year')
                }
            });
        })
    },
    methods:{
        sepYear(year){                   
            let yearArray = year.replace(/\s/g, '').split('-');
            return yearArray
        },
        isElementInViewport (element){
            var rect = element.getBoundingClientRect();

            // console.log(rect)

            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && 
                rect.right <= (window.innerWidth || document.documentElement.clientWidth) 
            )

        }
    }
}
</script>